const supplierTeamAgentRoutes = {
  path: "/supplier_team/agent",
  component: () => import("layouts/GenericPortalLayout.vue"),
  children: [
    {
      path: "skus",
      name: "SupplierAgentSKUs",
      children: [
        {
          path: "seller_skus/live",
          name: "SupplierAgentManageLiveSellerSKUs",
          component: () =>
            import("pages/supplyTeam/SupplierAgentManageLiveSellerSKUs.vue"),
        },
        {
          path: "seller_skus/create",
          name: "SupplierAgentCreateSellerSKU",
          component: () =>
            import("pages/supplyTeam/SupplierAgentCreateSellerSKU.vue"),
        },
        {
          path: "seller_skus/draft",
          name: "SupplierAgentManageSellerDraftSKUs",
          component: () =>
            import("pages/supplyTeam/SupplierAgentManageSellerDraftSKUs.vue"),
        },
        {
          path: "seller_skus/:skuId/edit",
          name: "SupplierAgentEditSellerSKU",
          component: () =>
            import("pages/supplyTeam/SupplierAgentEditSellerSKU.vue"),
        },
      ],
    },
    {
      path: "categories/:categoryId/skus/compare",
      name: "SupplierAgentCompareSKUs",
      component: () => import("pages/supplyTeam/SupplierAgentCompareSKUs.vue"),
    },

    {
      path: "master_orders/:masterOrderId",
      name: "SupplierAgentViewMasterOrder",
      component: () =>
        import("pages/supplyTeam/SupplierAgentViewMasterOrder.vue"),
    },
    {
      path: "master_orders",
      name: "SupplierAgentMasterOrdersPath",
      children: [
        {
          path: "",
          name: "SupplierAgentMasterOrdersSearch",
          component: () =>
            import("pages/supplyTeam/SupplierAgentMasterOrdersSearch.vue"),
        },
      ],
    },
    {
      path: "bidso/addresses",
      name: "SupplierAgentManageBidsoAddresses",
      component: () =>
        import("src/pages/supplyTeam/SupplierAgentManageBidsoAddresses.vue"),
    },
  ],
};

export default supplierTeamAgentRoutes;

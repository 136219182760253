const supplierTeamAdminRoutes = {
  path: "/supplier_team/admin",
  component: () => import("layouts/GenericPortalLayout.vue"),
  children: [
    {
      path: "skus",
      name: "SupplierAdminSKUs",
      children: [
        {
          path: "seller_skus/live",
          name: "SupplierAdminManageLiveSellerSKUs",
          component: () =>
            import("pages/supplyAdmin/SupplierAdminManageLiveSellerSKUs.vue"),
        },
        {
          path: "seller_skus/create",
          name: "SupplierAdminCreateSellerSKU",
          component: () =>
            import("pages/supplyAdmin/SupplierAdminCreateSellerSKU.vue"),
        },
        {
          path: "seller_skus/draft",
          name: "SupplierAdminManageSellerDraftSKUs",
          component: () =>
            import("pages/supplyAdmin/SupplierAdminManageSellerDraftSKUs.vue"),
        },

        {
          path: "seller_skus/:skuId/edit",
          name: "SupplierAdminEditSellerSKU",
          component: () =>
            import("pages/supplyAdmin/SupplierAdminEditSellerSKU.vue"),
        },
      ],
    },

    {
      path: "categories/:categoryId/skus/compare",
      name: "SupplierAdminCompareSKUs",
      component: () => import("pages/supplyAdmin/SupplierAdminCompareSKUs.vue"),
    },
    {
      path: "master_orders/:masterOrderId",
      name: "SupplierAdminViewMasterOrder",
      component: () =>
        import("pages/supplyAdmin/SupplierAdminViewMasterOrder.vue"),
    },
    {
      path: "master_orders",
      name: "SupplierAdminMasterOrdersPath",
      children: [
        {
          path: "",
          name: "SupplierAdminMasterOrdersSearch",
          component: () =>
            import("pages/supplyAdmin/SupplierAdminMasterOrdersSearch.vue"),
        },
      ],
    },
    {
      path: "bidso/addresses",
      name: "SupplierAdminManageBidsoAddresses",
      component: () =>
        import("src/pages/supplyAdmin/SupplierAdminManageBidsoAddresses.vue"),
    },

    {
      path: "approvals/",
      children: [
        {
          path: "seller_orders",
          name: "SupplierAdminApproveSellerOrders",
          component: () =>
            import("pages/supplyAdmin/SupplierAdminSellerOrderApprovals.vue"),
        },
      ],
    },
  ],
};

export default supplierTeamAdminRoutes;

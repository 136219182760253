const adminRoutes = {
  path: "/admin/",
  component: () => import("layouts/GenericPortalLayout.vue"),
  children: [
    {
      path: "categories/manage",
      name: "AdminManageCategories",
      component: () => import("pages/admin/AdminManageCategories.vue"),
    },
    {
      path: "categories/:categoryId/edit",
      name: "AdminEditCategory",
      component: () => import("pages/admin/AdminManageCategory.vue"),
    },
    {
      path: "categories/:categoryId/fulfillment_workflow",
      name: "AdminEditFulfillmentWorkflow",
      component: () =>
        import("pages/admin/AdminCategoryFulfillmentWorkflowManager.vue"),
    },
    {
      path: "categories/:categoryId/conditions/create",
      name: "AdminCreateConditions",
      component: () => import("pages/admin/AdminCreateConditions.vue"),
    },
    {
      path: "categories/:categoryId/conditions/:conditionId/edit",
      name: "AdminEditConditions",
      component: () => import("pages/admin/AdminEditCondition.vue"),
    },

    {
      path: "databases/manage",
      name: "AdminManageDatabases",
      component: () => import("pages/admin/AdminSheetDatabaseManager.vue"),
    },
    {
      path: "users/manage",
      name: "AdminManageUsers",
      component: () => import("pages/admin/AdminManageUsers.vue"),
    },
    {
      path: "categories/:categoryId/skus/compare",
      name: "AdminCompareSKUs",
      component: () => import("pages/admin/AdminCompareSKUs.vue"),
    },

    {
      path: "users/manage/password",
      name: "AdminSetPassword",
      component: () => import("pages/admin/AdminSetPasswordPage.vue"),
    },
    {
      path: "skus",
      name: "AdminSKUs",
      children: [
        {
          path: "seller_skus/live",
          name: "AdminManageLiveSellerSKUs",
          component: () => import("pages/admin/AdminManageLiveSellerSKUs.vue"),
        },
        {
          path: "seller_skus/draft",
          name: "AdminManageSellerDraftSKUs",
          component: () => import("pages/admin/AdminManageSellerDraftSKUs.vue"),
        },
        {
          path: "buyer_skus",
          name: "AdminBuyerSKUs",

          children: [
            {
              path: "",
              name: "AdminViewBuyerSKUs",
              component: () => import("pages/admin/AdminViewBuyerSKUs.vue"),
            },
            {
              path: "create",
              name: "AdminCreateBuyerSKU",
              component: () => import("pages/admin/AdminCreateBuyerSKU.vue"),
            },
            {
              path: ":buyerSKUId/edit",
              name: "AdminEditBuyerSKU",
              component: () => import("pages/admin/AdminEditBuyerSKU.vue"),
            },
            {
              path: "pricing_requests",
              name: "AdminBuyerSKUPriceChangeRequests",
              component: () =>
                import("pages/admin/AdminBuyerSKUPriceChangeRequests.vue"),
            },
            {
              path: "pricing_requests/:pricingRequestId",
              name: "AdminBuyerSKUPriceChangeRequest",
              component: () =>
                import("pages/admin/AdminBuyerSKUPriceChangeRequest.vue"),
            },
          ],
        },
        {
          path: "sample_skus",
          name: "AdminSampleSKUs",

          children: [
            {
              path: "",
              name: "AdminViewSampleSKUs",
              component: () => import("pages/admin/AdminViewSampleSKUs.vue"),
            },
            {
              path: "create",
              name: "AdminCreateSampleSKU",
              component: () => import("pages/admin/AdminCreateSampleSKU.vue"),
            },
            {
              path: ":sampleSKUId/edit",
              name: "AdminEditSampleSKU",
              component: () => import("pages/admin/AdminEditSampleSKU.vue"),
            },
          ],
        },
      ],
    },

    {
      path: "master_orders",
      name: "AdminMasterOrders",
      children: [
        {
          path: "",
          name: "AdminSearchMasterOrders",
          component: () => import("pages/admin/AdminMasterOrdersSearch.vue"),
        },
        {
          path: "qc/calendar",
          name: "AdminOrdersQCCalendar",
          component: () => import("pages/admin/AdminOrdersQCCalendar.vue"),
        },
      ],
    },
    {
      path: "master_orders/:masterOrderId",
      name: "AdminViewMasterOrder",
      component: () => import("pages/admin/AdminViewMasterOrder.vue"),
    },
    {
      path: "sample_orders",
      name: "AdminViewSampleOrders",
      component: () => import("pages/admin/AdminViewSampleOrders.vue"),
    },
    {
      path: "sample_orders/:sampleOrderId",
      children: [
        {
          path: "",
          name: "AdminViewSampleOrder",
          component: () => import("pages/admin/AdminViewSampleOrder.vue"),
        },
      ],
    },
    {
      path: "approvals",
      children: [
        {
          path: "seller_orders",
          name: "AdminSellerOrderApprovals",
          component: () => import("pages/admin/AdminSellerOrderApprovals.vue"),
        },
        {
          path: "qc",
          name: "AdminQCApprovals",
          component: () => import("pages/admin/AdminQCApprovals.vue"),
        },
      ],
    },
    {
      path: "bidso/addresses",
      name: "AdminManageBidsoAddresses",
      component: () => import("src/pages/admin/AdminManageBidsoAddresses.vue"),
    },
  ],
};
export default adminRoutes;

const bizFinTeamRoutes = {
  path: "/biz_fin/",
  component: () => import("layouts/GenericPortalLayout.vue"),
  children: [
    {
      path: "master_orders",
      name: "BizFinSearchMasterOrders",
      component: () => import("pages/bizFin/BizFinSearchMasterOrders.vue"),
    },
    {
      path: "master_orders/:masterOrderId",
      name: "BizFinViewMasterOrder",
      component: () => import("pages/bizFin/BizFinViewMasterOrder.vue"),
    },
  ],
};
export default bizFinTeamRoutes;

export const USER_TYPES = {
  BUYER: "buyer",
  SELLER: "seller",
  ADMIN: "admin",
  CATALOG_MANAGER: "catalog_manager",
  DEMAND_KAM: "demand_key_account_manager",
  FIELD_OPERATOR: "field_operator",
};

export const BIDSO_USER_CONTEXT = {
  ADMIN: "admin",
  SUPPLY_ADMIN: "supply_admin",
  SUPPLY_TEAM: "supply_team",
  DEMAND: "demand",
  BUYER: "buyer",
  SELLER: "seller",
  FIELD_OPERATOR: "field_operator",
  BIZ_OPS_TEAM: "biz_ops_team",
};

export const USER_TYPES_V2 = {
  BUYER: "buyer",
  SELLER: "seller",
  BIDSO: "bidso",
};

export const BIDSO_USER_ROLES = {
  ADMIN: "bidso_admin",
  DEMAND_KAM: "bidso_demand_kam",
  CATALOG_MANAGER: "bidso_catalog_manager",
  FIELD_OPERATOR: "bidso_field_operator",
  BIDSO_SUPPLIER_TEAM: "bidso_supplier_team",
  BIDSO_SUPPLIER_TEAM_ADMIN: "bidso_supplier_team_admin",
  BIDSO_BIZ_OPS_TEAM: "bidso_biz_ops_team",
  BIDSO_BIZ_FIN: "bidso_biz_fin_team",
  BIDSO_LOGISTICS_TEAM: "bidso_logistics_team",
  DEV_ADMIN: "bidso_dev_admin",
  BIDSO_ANALYST: "bidso_analyst",
};

export const BIDSO_USER_ROLES_FRIENDLY_NAME = {
  [BIDSO_USER_ROLES.ADMIN]: "Bidso Administrator",
  [BIDSO_USER_ROLES.DEMAND_KAM]: "Demand KAM",
  [BIDSO_USER_ROLES.CATALOG_MANAGER]: "Catalog manager",
  [BIDSO_USER_ROLES.FIELD_OPERATOR]: "Field Operator",
  [BIDSO_USER_ROLES.BIDSO_SUPPLIER_TEAM]: "Supplier Team",
  [BIDSO_USER_ROLES.BIDSO_SUPPLIER_TEAM_ADMIN]: "Supplier Team Lead",
  [BIDSO_USER_ROLES.BIDSO_BIZ_OPS_TEAM]: "BizOps Team",
  [BIDSO_USER_ROLES.BIDSO_BIZ_FIN]: "BizFin Team",
  [BIDSO_USER_ROLES.BIDSO_LOGISTICS_TEAM]: "Logistics Team",
  [BIDSO_USER_ROLES.DEV_ADMIN]: "Developer Admin",
  [BIDSO_USER_ROLES.BIDSO_ANALYST]: "Bidso Analyst",
};

export const USER_ROLES = {
  ADMIN: "admin",
};

export const USER_HOMES = {
  [USER_TYPES_V2.BUYER]: {
    roles: { [USER_ROLES.ADMIN]: "/buyer/master_orders" },
    default: "/buyer/master_orders",
  },
  [USER_TYPES_V2.SELLER]: {
    roles: { [USER_ROLES.ADMIN]: "/seller/master_orders" },
    default: "/seller/master_orders",
  },
  [USER_TYPES_V2.BIDSO]: {
    roles: {
      [BIDSO_USER_ROLES.FIELD_OPERATOR]: "/fo/orders",
      [BIDSO_USER_ROLES.DEV_ADMIN]: "/dev_admin/products",
      [BIDSO_USER_ROLES.BIDSO_ANALYST]: "/reports/master_order",
    },
    default: "/dashboard",
  },
};

export const USER_DEPARTMENTS = [
  "Accounts",
  "Operations",
  "IT",
  "Sales",
  "Senior Management/ Owner",
];

export const USER_ACCESS_OPTIONS = [
  { label: "Bidso", value: { user_type: "bidso", user_role: "bidso_all" } },
  { label: "Buyer", value: { user_type: "buyer", user_role: "all" } },
  { label: "Seller", value: { user_type: "seller", user_role: "all" } },
];

import { BIDSO_USER_ROLES } from "./userConstants";
export const PAGE_NAME_MAPPING = {
  DASHBOARD_PAGE: "DashboardPage",
  PROFILE_PAGE: "ProfilePage",
  MANAGE_BIDSO_SKUS_PAGE: "ManageBidsoSKUsPage",
  EDIT_BIDSO_SKU_PAGE: "EditBidsoSKUPage",
  CREATE_BIDSO_SKU_PAGE: "CreateBidsoSKUPage",
  MANAGE_BUYERS_PAGE: "ManageBuyersPage",
  EDIT_BUYER_PAGE: "EditBuyerPage",
  VIEW_BUYER_PAGE: "ViewBuyerPage",
  MANAGE_SELLERS_PAGE: "ManageSellersPage",
  EDIT_SELLER_PAGE: "EditSellerPage",
  SELLER_CATALOG_PAGE: "SellerCatalogPage",
  SELLER_PRODUCT_SKUS_PAGE: "SellerProductSKUsPage",
  ADD_SELLER_PAGE: "AddSellerPage",
  VIEW_SELLER_PAGE: "ViewSellerPage",
  MASTER_ORDER_CART_PAGE: "MasterOrderCartPage",
  SAMPLES_ORDER_CART_PAGE: "SamplesOrderCartPage",
  MANAGE_TRANSPORT_REQUESTS: "ManageTransportRequests",
  CREATE_TRANSPORT_REQUEST: "CreateTransportRequest",
  EDIT_TRANSPORT_REQUEST: "EditTransportRequest",
  MANAGE_TRANSPORT_BOOKINGS: "ManageTransportBookings",
  EDIT_TRANSPORT_BOOKING: "EditTransportBooking",
  EDIT_TRANSPORT_BILLING_DETAILS: "EditTransportBillingDetails",
  MANAGE_TRANSPORT_DUES: "ManageTransportDues",
  MANAGE_BUYER_SETTLEMENTS: "ManageBuyerSettlements",
  VIEW_BUSINESS_REPORT: "ViewBusinessReport",
  VIEW_BUYER_REPORTS: "ViewBuyerReports",
  VIEW_BUYER_SKU_SUMMARY_REPORT: "ViewBuyerSKUSummaryReport",
  VIEW_QC_REPORTS: "ViewQCReports",
};

export const MODULE_NAME_MAPPING = {
  BIDSO_SKU: "BIDSO_SKU",
  BUYERS: "BUYERS",
  SELLERS: "SELLERS",
  DASHBOARD: "DASHBOARD",
  PROFILE: "PROFILE",
  MASTER_ORDER_CART: "MASTER_ORDER_CART",
  SAMPLES_ORDER_CART: "SAMPLES_ORDER_CART",
  LOGISTICS: "LOGISTICS",
  REPORTS: "REPORTS",
};

export const PAGE_DETAILS_MAP = {
  [PAGE_NAME_MAPPING.DASHBOARD_PAGE]: {
    modules: [MODULE_NAME_MAPPING.DASHBOARD],
    breadcrumbs: () => [],
    selectedRouteId: "dashboard",
  },
  [PAGE_NAME_MAPPING.PROFILE_PAGE]: {
    modules: [MODULE_NAME_MAPPING.PROFILE],
    breadcrumbs: () => [{ name: "Profile", link: "#" }],
    selectedRouteId: "my_profile",
  },
  [PAGE_NAME_MAPPING.MANAGE_BIDSO_SKUS_PAGE]: {
    modules: [MODULE_NAME_MAPPING.BIDSO_SKU],
    breadcrumbs: () => [
      { name: "Manage Bidso SKUs", link: "/skus/bidso_skus" },
    ],
    selectedRouteId: "bidso_skus",
    routePath: ["manage_skus"],
  },
  [PAGE_NAME_MAPPING.EDIT_BIDSO_SKU_PAGE]: {
    modules: [MODULE_NAME_MAPPING.BIDSO_SKU],
    breadcrumbs: () => [
      { name: "Manage Bidso SKUs", link: "/skus/bidso_skus" },
      {
        name: "Edit Bidso SKU",
        link: "#",
      },
    ],
    selectedRouteId: "bidso_skus",
    routePath: ["manage_skus"],
  },
  [PAGE_NAME_MAPPING.CREATE_BIDSO_SKU_PAGE]: {
    modules: [MODULE_NAME_MAPPING.BIDSO_SKU],
    breadcrumbs: () => [
      { name: "Manage Bidso SKUs", link: "/skus/bidso_skus" },
      { name: "Create Bidso SKU", link: "#" },
    ],
    selectedRouteId: "bidso_skus",
    routePath: ["manage_skus"],
  },
  [PAGE_NAME_MAPPING.MANAGE_BUYERS_PAGE]: {
    modules: [MODULE_NAME_MAPPING.BUYERS],
    breadcrumbs: () => [{ name: "Manage Buyers", link: "#" }],
    selectedRouteId: "manage_buyers",
  },
  [PAGE_NAME_MAPPING.EDIT_BUYER_PAGE]: {
    modules: [MODULE_NAME_MAPPING.BUYERS],
    breadcrumbs: (buyerName = "") => [
      { name: "Manage Buyers", link: "/buyers/manage" },
      { name: `Edit Buyer - ${buyerName}`, link: "#" },
    ],
    selectedRouteId: "manage_buyers",
  },
  [PAGE_NAME_MAPPING.VIEW_BUYER_PAGE]: {
    modules: [MODULE_NAME_MAPPING.BUYERS],
    breadcrumbs: (buyerName = "") => [
      { name: "Manage Buyers", link: "/buyers/manage" },
      { name: `View Buyer - ${buyerName}`, link: "#" },
    ],
    selectedRouteId: "manage_buyers",
  },
  [PAGE_NAME_MAPPING.MANAGE_SELLERS_PAGE]: {
    modules: [MODULE_NAME_MAPPING.SELLERS],
    breadcrumbs: () => [{ name: "Manage Sellers", link: "#" }],
    selectedRouteId: "manage_suppliers",
  },
  [PAGE_NAME_MAPPING.EDIT_SELLER_PAGE]: {
    modules: [MODULE_NAME_MAPPING.SELLERS],
    breadcrumbs: (sellerName = "") => [
      { name: "Manage Sellers", link: "/sellers/manage" },
      { name: `Edit Seller - ${sellerName}`, link: "#" },
    ],
    selectedRouteId: "manage_suppliers",
  },
  [PAGE_NAME_MAPPING.VIEW_SELLER_PAGE]: {
    modules: [MODULE_NAME_MAPPING.SELLERS],
    breadcrumbs: () => [
      { name: "Manage Sellers", link: "/sellers/manage" },
      { name: "View Seller", link: "#" },
    ],
    selectedRouteId: "manage_suppliers",
  },
  [PAGE_NAME_MAPPING.SELLER_CATALOG_PAGE]: {
    modules: [MODULE_NAME_MAPPING.SELLERS],
    breadcrumbs: () => [
      { name: "Manage Sellers", link: "/sellers/manage" },
      { name: "Seller Catalog", link: "#" },
    ],
    selectedRouteId: "manage_suppliers",
  },
  [PAGE_NAME_MAPPING.SELLER_PRODUCT_SKUS_PAGE]: {
    modules: [MODULE_NAME_MAPPING.SELLERS],
    breadcrumbs: (sellerId = "") => [
      { name: "Manage Sellers", link: "/sellers/manage" },
      { name: "Seller Catalog", link: `/sellers/${sellerId}/catalog` },
      {
        name: "Catalog SKUs",
        link: "#",
      },
    ],
    selectedRouteId: "manage_suppliers",
  },
  [PAGE_NAME_MAPPING.ADD_SELLER_PAGE]: {
    modules: [MODULE_NAME_MAPPING.SELLERS],
    breadcrumbs: () => [
      { name: "Manage Sellers", link: "/sellers/manage" },
      { name: "Add Seller", link: "#" },
    ],
    selectedRouteId: "manage_suppliers",
  },
  [PAGE_NAME_MAPPING.MASTER_ORDER_CART_PAGE]: {
    modules: [MODULE_NAME_MAPPING.MASTER_ORDER_CART],
    breadcrumbs: () => [{ name: "Master Order Cart", link: "#" }],
    selectedRouteId: "order_cart",
  },
  [PAGE_NAME_MAPPING.SAMPLES_ORDER_CART_PAGE]: {
    modules: [MODULE_NAME_MAPPING.SAMPLES_ORDER_CART],
    breadcrumbs: () => [{ name: "Samples Order Cart", link: "#" }],
    selectedRouteId: "samples_cart",
  },
  [PAGE_NAME_MAPPING.MANAGE_TRANSPORT_REQUESTS]: {
    modules: [MODULE_NAME_MAPPING.LOGISTICS],
    breadcrumbs: () => [{ name: "Manage Transport Requests", link: "#" }],
    routePath: ["logistics"],
    selectedRouteId: "transport_requests",
  },
  [PAGE_NAME_MAPPING.MANAGE_TRANSPORT_DUES]: {
    modules: [MODULE_NAME_MAPPING.LOGISTICS],
    breadcrumbs: () => [{ name: "Manage Transport Payment Dues", link: "#" }],
    routePath: ["logistics"],
    selectedRouteId: "transport_payment_dues",
  },
  [PAGE_NAME_MAPPING.MANAGE_TRANSPORT_BOOKINGS]: {
    modules: [MODULE_NAME_MAPPING.LOGISTICS],
    breadcrumbs: () => [{ name: "Manage Transport Bookings", link: "#" }],
    routePath: ["logistics"],
    selectedRouteId: "transport_bookings",
  },
  [PAGE_NAME_MAPPING.MANAGE_BUYER_SETTLEMENTS]: {
    modules: [MODULE_NAME_MAPPING.LOGISTICS],
    breadcrumbs: () => [
      {
        name: "Manage Transport Payment Dues",
        link: "/logistics/transport_payment_dues",
      },
      { name: "Manage Buyer Settlements", link: "#" },
    ],
    routePath: ["logistics"],
    selectedRouteId: "transport_payment_dues",
  },
  [PAGE_NAME_MAPPING.EDIT_TRANSPORT_REQUEST]: {
    modules: [MODULE_NAME_MAPPING.LOGISTICS],
    breadcrumbs: () => [
      {
        name: "Manage Transport Requests",
        icon: "local_shipping",
        link: "/logistics/transport_requests",
      },
      {
        name: "Edit Request",
        link: "#",
      },
    ],
    routePath: ["logistics"],
    selectedRouteId: "transport_requests",
  },
  [PAGE_NAME_MAPPING.EDIT_TRANSPORT_BOOKING]: {
    modules: [MODULE_NAME_MAPPING.LOGISTICS],
    breadcrumbs: () => [
      {
        name: "Manage Bookings",
        icon: "local_shipping",
        link: "/logistics/transport_bookings",
      },
      {
        name: "Edit Booking",
        link: "#",
      },
    ],
    routePath: ["logistics"],
    selectedRouteId: "transport_bookings",
  },
  [PAGE_NAME_MAPPING.EDIT_TRANSPORT_BILLING_DETAILS]: {
    modules: [MODULE_NAME_MAPPING.LOGISTICS],
    breadcrumbs: () => [
      {
        name: "Manage Bookings",
        icon: "local_shipping",
        link: "/logistics/transport_bookings",
      },
      {
        name: "Edit Billing Details",
        link: "#",
      },
    ],
    routePath: ["logistics"],
    selectedRouteId: "transport_bookings",
  },
  [PAGE_NAME_MAPPING.CREATE_TRANSPORT_REQUEST]: {
    modules: [MODULE_NAME_MAPPING.LOGISTICS],
    breadcrumbs: () => [
      {
        name: "Manage Requests",
        icon: "local_shipping",
        link: "/logistics/transport_requests",
      },
      {
        name: "Add Request",
        link: "#",
      },
    ],
    routePath: ["logistics"],
    selectedRouteId: "transport_requests",
  },
  [PAGE_NAME_MAPPING.VIEW_BUSINESS_REPORT]: {
    modules: [MODULE_NAME_MAPPING.REPORTS],
    breadcrumbs: () => [
      {
        name: "Business Reports",
        icon: "shopping_cart",
        link: "#",
      },
    ],
    routePath: ["reports"],
    selectedRouteId: "master_order_reports",
  },
  [PAGE_NAME_MAPPING.VIEW_QC_REPORTS]: {
    modules: [MODULE_NAME_MAPPING.REPORTS],
    breadcrumbs: () => [
      {
        name: "QC Reports",
        icon: "verified_user",
        link: "#",
      },
    ],
    routePath: ["reports"],
    selectedRouteId: "qc_reports",
  },
  [PAGE_NAME_MAPPING.VIEW_BUYER_REPORTS]: {
    modules: [MODULE_NAME_MAPPING.REPORTS],
    breadcrumbs: () => [
      {
        name: "Buyer(s) Reports",
        icon: "fa fa-user-tie",
        link: "#",
      },
    ],
    routePath: ["reports"],
    selectedRouteId: "buyers_reports",
  },
  [PAGE_NAME_MAPPING.VIEW_BUYER_SKU_SUMMARY_REPORT]: {
    modules: [MODULE_NAME_MAPPING.REPORTS],
    breadcrumbs: () => [
      {
        name: "Buyer(s) Reports",
        icon: "fa fa-user-tie",
        link: "/reports/buyer_skus",
      },
      {
        name: "Buyer SKU Reports",
        link: "#",
      },
    ],
    routePath: ["reports"],
    selectedRouteId: "buyers_reports",
  },
};

export const ROLE_CONFIG_MAP = {
  [BIDSO_USER_ROLES.ADMIN]: () =>
    import(
      "src/components/bidso-commons/bidso-constants/roleConfig/adminConfig.js"
    ),
  [BIDSO_USER_ROLES.BIDSO_BIZ_OPS_TEAM]: () =>
    import(
      "src/components/bidso-commons/bidso-constants/roleConfig/bizOpsTeamConfig.js"
    ),
  [BIDSO_USER_ROLES.DEMAND_KAM]: () =>
    import(
      "src/components/bidso-commons/bidso-constants/roleConfig/demandKAMConfig.js"
    ),
  [BIDSO_USER_ROLES.BIDSO_SUPPLIER_TEAM_ADMIN]: () =>
    import(
      "src/components/bidso-commons/bidso-constants/roleConfig/supplierTeamAdminConfig.js"
    ),
  [BIDSO_USER_ROLES.BIDSO_SUPPLIER_TEAM]: () =>
    import(
      "src/components/bidso-commons/bidso-constants/roleConfig/supplierTeamConfig.js"
    ),
  [BIDSO_USER_ROLES.CATALOG_MANAGER]: () =>
    import(
      "src/components/bidso-commons/bidso-constants/roleConfig/catalogManagerConfig.js"
    ),
  [BIDSO_USER_ROLES.BIDSO_BIZ_FIN]: () =>
    import(
      "src/components/bidso-commons/bidso-constants/roleConfig/bizFinConfig.js"
    ),
  [BIDSO_USER_ROLES.BIDSO_LOGISTICS_TEAM]: () =>
    import(
      "src/components/bidso-commons/bidso-constants/roleConfig/logisticsTeamConfig.js"
    ),
  [BIDSO_USER_ROLES.BIDSO_ANALYST]: () =>
    import(
      "src/components/bidso-commons/bidso-constants/roleConfig/analystConfig.js"
    ),
};

const catalogManagerRoutes = {
  path: "/catalog_manager/",
  component: () => import("layouts/GenericPortalLayout.vue"),
  children: [
    {
      path: "sample_skus",
      name: "CatalogSampleSKUs",

      children: [
        {
          path: "",
          name: "CatalogManagerManageSampleSKUs",
          component: () =>
            import("pages/catalog/CatalogManagerManageSampleSKUs.vue"),
        },
        {
          path: "create",
          name: "CatalogManagerCreateSampleSKU",
          component: () =>
            import("pages/catalog/CatalogManagerCreateSampleSKU.vue"),
        },
        {
          path: ":sampleSKUId/edit",
          name: "CatalogManagerEditSampleSKU",
          component: () =>
            import("pages/catalog/CatalogManagerEditSampleSKU.vue"),
        },
      ],
    },
    {
      path: "seller_skus/live",
      name: "CatalogManagerManageLiveSellerSKUs",
      component: () =>
        import("pages/catalog/CatalogManagerManageLiveSellerSKUs.vue"),
    },
    {
      path: "seller_skus/create",
      name: "CatalogManagerCreateSellerSKU",
      component: () =>
        import("pages/catalog/CatalogManagerCreateSellerSKU.vue"),
    },
    {
      path: "seller_skus/draft",
      name: "CatalogManagerManageSellerDraftSKUs",
      component: () =>
        import("pages/catalog/CatalogManagerManageSellerDraftSKUs.vue"),
    },
    {
      path: "seller_skus/:skuId/edit",
      name: "CatalogManagerEditSellerSKU",
      component: () => import("pages/catalog/CatalogManagerEditSellerSKU.vue"),
    },
  ],
};

export default catalogManagerRoutes;

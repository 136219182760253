import {
  PAGE_NAME_MAPPING,
  PAGE_DETAILS_MAP,
} from "../components/bidso-commons/bidso-constants/pageAndModuleContants";
const genericRoutes = {
  path: "/",
  component: () => import("layouts/GenericPortalLayout.vue"),
  children: [
    {
      path: "dashboard",
      name: PAGE_NAME_MAPPING.DASHBOARD_PAGE,
      component: () => import("pages/generic/DashboardPage.vue"),
      meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.DASHBOARD_PAGE],
    },
    {
      path: "profile",
      name: PAGE_NAME_MAPPING.PROFILE_PAGE,
      component: () => import("pages/generic/ProfilePage.vue"),
      meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.PROFILE_PAGE],
    },
    {
      path: "skus/bidso_skus",
      name: PAGE_NAME_MAPPING.MANAGE_BIDSO_SKUS_PAGE,
      component: () => import("pages/generic/ManageBidsoSKUsPage.vue"),
      meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.MANAGE_BIDSO_SKUS_PAGE],
    },
    {
      path: "skus/bidso_skus/:bidsoSKUId/edit",
      name: PAGE_NAME_MAPPING.EDIT_BIDSO_SKU_PAGE,
      component: () => import("pages/generic/EditBidsoSKUPage.vue"),
      meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.EDIT_BIDSO_SKU_PAGE],
    },
    {
      path: "skus/bidso_skus/create",
      name: PAGE_NAME_MAPPING.CREATE_BIDSO_SKU_PAGE,
      component: () => import("pages/generic/CreateBidsoSKUPage.vue"),
      meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.CREATE_BIDSO_SKU_PAGE],
    },
    {
      path: "buyers/manage",
      name: PAGE_NAME_MAPPING.MANAGE_BUYERS_PAGE,
      component: () => import("pages/generic/ManageBuyersPage.vue"),
      meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.MANAGE_BUYERS_PAGE],
    },
    {
      path: "buyers/:buyerId/edit",
      name: PAGE_NAME_MAPPING.EDIT_BUYER_PAGE,
      component: () => import("pages/generic/EditBuyerPage.vue"),
      meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.EDIT_BUYER_PAGE],
    },
    {
      path: "buyers/:buyerId",
      name: PAGE_NAME_MAPPING.VIEW_BUYER_PAGE,
      component: () => import("pages/generic/ViewBuyerPage.vue"),
      meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.VIEW_BUYER_PAGE],
    },
    {
      path: "sellers/manage",
      name: PAGE_NAME_MAPPING.MANAGE_SELLERS_PAGE,
      component: () => import("pages/generic/ManageSellersPage.vue"),
      meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.MANAGE_SELLERS_PAGE],
    },
    {
      path: "sellers/:sellerId/edit",
      name: PAGE_NAME_MAPPING.EDIT_SELLER_PAGE,
      component: () => import("pages/generic/EditSellerPage.vue"),
      meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.EDIT_SELLER_PAGE],
    },
    {
      path: "sellers/:sellerId/catalog",
      name: PAGE_NAME_MAPPING.SELLER_CATALOG_PAGE,
      component: () => import("pages/generic/SellerCatalogPage.vue"),
      meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.SELLER_CATALOG_PAGE],
    },
    {
      path: "sellers/:sellerId/products/:productId/skus",
      name: PAGE_NAME_MAPPING.SELLER_PRODUCT_SKUS_PAGE,
      component: () => import("pages/generic/SellerProductSKUsPage.vue"),
      meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.SELLER_PRODUCT_SKUS_PAGE],
    },
    {
      path: "sellers/add",
      name: PAGE_NAME_MAPPING.ADD_SELLER_PAGE,
      component: () => import("pages/generic/AddSellerPage.vue"),
      meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.ADD_SELLER_PAGE],
    },
    {
      path: "sellers/:sellerId",
      name: PAGE_NAME_MAPPING.VIEW_SELLER_PAGE,
      component: () => import("pages/generic/ViewSellerPage.vue"),
      meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.VIEW_SELLER_PAGE],
    },
    {
      path: "master_order_cart",
      name: PAGE_NAME_MAPPING.MASTER_ORDER_CART_PAGE,
      component: () => import("pages/generic/MasterOrderCartPage.vue"),
      meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.MASTER_ORDER_CART_PAGE],
    },
    {
      path: "samples_order_cart",
      name: PAGE_NAME_MAPPING.SAMPLES_ORDER_CART_PAGE,
      component: () => import("pages/generic/SamplesOrderCartPage.vue"),
      meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.SAMPLES_ORDER_CART_PAGE],
    },
    {
      path: "logistics/",
      children: [
        {
          path: "transport_requests",
          name: PAGE_NAME_MAPPING.MANAGE_TRANSPORT_REQUESTS,
          component: () => import("pages/generic/ManageTransportRequests.vue"),
          meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.MANAGE_TRANSPORT_REQUESTS],
        },
        {
          path: "transport_requests/add/:transportType",
          name: PAGE_NAME_MAPPING.CREATE_TRANSPORT_REQUEST,
          component: () => import("pages/generic/CreateTransportRequest.vue"),
          meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.CREATE_TRANSPORT_REQUEST],
        },
        {
          path: "transport_requests/:requestId/edit",
          name: PAGE_NAME_MAPPING.EDIT_TRANSPORT_REQUEST,
          component: () => import("pages/generic/EditTransportRequest.vue"),
          meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.EDIT_TRANSPORT_REQUEST],
        },
        {
          path: "transport_bookings",
          name: PAGE_NAME_MAPPING.MANAGE_TRANSPORT_BOOKINGS,
          component: () => import("pages/generic/ManageTransportBookings.vue"),
          meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.MANAGE_TRANSPORT_BOOKINGS],
        },
        {
          path: "transport_requests/:requestId/transport_bookings/:bookingId/edit",
          name: PAGE_NAME_MAPPING.EDIT_TRANSPORT_BOOKING,
          component: () => import("pages/generic/EditTransportBooking.vue"),
          meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.EDIT_TRANSPORT_BOOKING],
        },
        {
          path: "transport_requests/:requestId/transport_bookings/:bookingId/edit/billing_details",
          name: PAGE_NAME_MAPPING.EDIT_TRANSPORT_BILLING_DETAILS,
          component: () =>
            import("pages/generic/EditTransportBillingDetails.vue"),
          meta: PAGE_DETAILS_MAP[
            PAGE_NAME_MAPPING.EDIT_TRANSPORT_BILLING_DETAILS
          ],
        },
        {
          path: "transport_payment_dues",
          name: PAGE_NAME_MAPPING.MANAGE_TRANSPORT_DUES,
          component: () => import("pages/generic/ManageTransportDues.vue"),
          meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.MANAGE_TRANSPORT_DUES],
        },
        {
          path: "settlements/buyers/:buyerId",
          name: PAGE_NAME_MAPPING.MANAGE_BUYER_SETTLEMENTS,
          component: () => import("pages/generic/ManageBuyerSettlements.vue"),
          meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.MANAGE_BUYER_SETTLEMENTS],
        },
      ],
    },
    {
      path: "reports",
      name: "ViewReports",
      children: [
        {
          path: "master_order",
          name: PAGE_NAME_MAPPING.VIEW_BUSINESS_REPORT,
          component: () => import("pages/generic/ViewBusinessReport.vue"),
          meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.VIEW_BUSINESS_REPORT],
        },
        {
          path: "qc",
          name: PAGE_NAME_MAPPING.VIEW_QC_REPORTS,
          component: () => import("pages/generic/ViewQCReports.vue"),
          meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.VIEW_QC_REPORTS],
        },
        {
          path: "buyer_skus",
          name: PAGE_NAME_MAPPING.VIEW_BUYER_REPORTS,
          component: () => import("pages/generic/ViewBuyerReports.vue"),
          meta: PAGE_DETAILS_MAP[PAGE_NAME_MAPPING.VIEW_BUYER_REPORTS],
        },
        {
          path: "buyer_skus/:buyerId/report",
          name: PAGE_NAME_MAPPING.VIEW_BUYER_SKU_SUMMARY_REPORT,
          component: () =>
            import("pages/generic/ViewBuyerSKUSummaryReport.vue"),
          meta: PAGE_DETAILS_MAP[
            PAGE_NAME_MAPPING.VIEW_BUYER_SKU_SUMMARY_REPORT
          ],
        },
      ],
    },
  ],
};
export default genericRoutes;

const logisticsTeamRoutes = {
  path: "/logistics_team/",
  component: () => import("layouts/GenericPortalLayout.vue"),
  children: [
    {
      path: "master_orders",
      name: "LogisticsTeamSearchMasterOrders",
      component: () =>
        import("pages/logisticsTeam/LogisticsTeamSearchMasterOrders.vue"),
    },
    {
      path: "master_orders/:masterOrderId",
      name: "LogisticsTeamViewMasterOrder",
      component: () =>
        import("pages/logisticsTeam/LogisticsTeamViewMasterOrder.vue"),
    },
  ],
};
export default logisticsTeamRoutes;

const bizOpsTeamRoutes = {
  path: "/biz_ops_team/",
  component: () => import("layouts/GenericPortalLayout.vue"),
  children: [
    {
      path: "skus",
      name: "BizOpsTeamSKUs",
      children: [
        {
          path: "seller_skus/live",
          name: "BizOpsManageSellerLiveSKUs",
          component: () =>
            import("pages/bizOpsTeam/BizOpsManageSellerLiveSKUs.vue"),
        },
        {
          path: "seller_skus/draft",
          name: "BizOpsManageSellerDraftSKUs",
          component: () =>
            import("pages/bizOpsTeam/BizOpsManageSellerDraftSKUs.vue"),
        },
        {
          path: "seller_skus/:skuId/edit",
          name: "BizOpsEditSellerSKU",
          component: () => import("pages/bizOpsTeam/BizOpsEditSellerSKU.vue"),
        },
        {
          path: "sample_skus",
          name: "BizOpsTeamSampleSKUs",

          children: [
            {
              path: "",
              name: "BizOpsTeamViewSampleSKUs",
              component: () =>
                import("pages/bizOpsTeam/BizOpsTeamViewSampleSKUs.vue"),
            },
            {
              path: "create",
              name: "BizOpsTeamCreateSampleSKU",
              component: () =>
                import("pages/bizOpsTeam/BizOpsTeamCreateSampleSKU.vue"),
            },
            {
              path: ":sampleSKUId/edit",
              name: "BizOpsTeamEditSampleSKU",
              component: () =>
                import("pages/bizOpsTeam/BizOpsTeamEditSampleSKU.vue"),
            },
          ],
        },

        {
          path: "buyer_skus",
          name: "BizOpsViewBuyerSKUs",
          component: () =>
            import("src/pages/bizOpsTeam/BizOpsViewBuyerSKUs.vue"),
        },
      ],
    },
    {
      path: "sample_orders",
      name: "BizOpsTeamManageSampleOrders",
      component: () =>
        import("pages/bizOpsTeam/BizOpsTeamManageSampleOrders.vue"),
    },
    {
      path: "sample_orders/:sampleOrderId",
      children: [
        {
          path: "",
          name: "BizOpsTeamManageSampleOrder",
          component: () =>
            import("pages/bizOpsTeam/BizOpsTeamManageSampleOrder.vue"),
        },
      ],
    },
    {
      path: "master_orders",
      name: "BizOpsMasterOrders",
      children: [
        {
          path: "",
          name: "BizOpsSearchMasterOrders",
          component: () =>
            import("pages/bizOpsTeam/BizOpsSearchMasterOrders.vue"),
        },
        {
          path: "qc/calendar",
          name: "BizOpsOrdersQCCalendar",
          component: () =>
            import("pages/bizOpsTeam/BizOpsOrdersQCCalendar.vue"),
        },
      ],
    },
    {
      path: "master_orders/:masterOrderId",
      name: "BizOpsViewMasterOrder",
      component: () => import("pages/bizOpsTeam/BizOpsViewMasterOrder.vue"),
    },
    {
      path: "approvals",
      name: "BizOpsTeamApprovals",
      children: [
        {
          path: "qc",
          name: "BizOpsQCApprovals",
          component: () => import("pages/bizOpsTeam/BizOpsQCApprovals.vue"),
        },
      ],
    },
    {
      path: "bidso/addresses",
      name: "BizOpsManageBidsoAddresses",
      component: () =>
        import("src/pages/bizOpsTeam/BizOpsManageBidsoAddresses.vue"),
    },
  ],
};
export default bizOpsTeamRoutes;

const demandKAMRoutes = {
  path: "/demand_kam/",
  component: () => import("layouts/GenericPortalLayout.vue"),
  children: [
    {
      path: "skus",
      name: "DemandKAMSKUs",
      children: [
        {
          path: "seller_skus/live",
          name: "KAMViewLiveSellerSKUs",
          component: () => import("pages/demandKAM/KAMViewLiveSellerSKUs.vue"),
        },
        {
          path: "buyer_skus",
          name: "KAMBuyerSKUs",

          children: [
            {
              path: "",
              name: "KAMViewBuyerSKUs",
              component: () => import("pages/demandKAM/KAMViewBuyerSKUs.vue"),
            },
            {
              path: "create",
              name: "KAMCreateBuyerSKU",
              component: () => import("pages/demandKAM/KAMCreateBuyerSKU.vue"),
            },
            {
              path: ":buyerSKUId/edit",
              name: "KAMEditBuyerSKU",
              component: () => import("pages/demandKAM/KAMEditBuyerSKU.vue"),
            },
            {
              path: "pricing_requests",
              name: "KAMBuyerSKUPriceChangeRequests",
              component: () =>
                import("pages/demandKAM/KAMBuyerSKUPriceChangeRequests.vue"),
            },
          ],
        },
        {
          path: "sample_skus",
          name: "KAMSampleSKUs",

          children: [
            {
              path: "",
              name: "KAMViewSampleSKUs",
              component: () => import("pages/demandKAM/KAMViewSampleSKUs.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "categories/:categoryId/skus/compare",
      name: "KAMCompareSKUs",
      component: () => import("pages/demandKAM/KAMCompareSKUs.vue"),
    },
    {
      path: "master_orders",
      name: "KAMMasterOrders",
      children: [
        {
          path: "",
          name: "KAMMasterOrdersSearch",
          component: () => import("pages/demandKAM/KAMMasterOrdersSearch.vue"),
        },
      ],
    },
    {
      path: "master_orders/:masterOrderId",
      name: "KAMViewMasterOrder",
      component: () => import("pages/demandKAM/KAMViewMasterOrder.vue"),
    },
    {
      path: "sample_orders",
      name: "KAMManageSampleOrders",
      component: () => import("pages/demandKAM/KAMManageSampleOrders.vue"),
    },
    {
      path: "sample_orders/:sampleOrderId",
      children: [
        {
          path: "",
          name: "KamManageSampleOrder",
          component: () => import("pages/demandKAM/KAMManageSampleOrder.vue"),
        },
      ],
    },
    {
      path: "bidso/addresses",
      name: "KAMManageBidsoAddresses",
      component: () =>
        import("src/pages/demandKAM/KAMManageBidsoAddresses.vue"),
    },
  ],
};
export default demandKAMRoutes;
